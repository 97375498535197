import { useInactiveQuests } from 'hooks/quests';
import QuestCard from './QuestCard';

function InactiveQuest() {
  const quests = useInactiveQuests();
  return (
    <div className="md:mt-5 mt-6 text-white flex flex-col md:gap-10 gap-6">
      {quests.map((quest, index) => {
        if (!quest) return;

        return (
          <QuestCard
            key={index}
            quest={quest}
            hideAdditionalDescriptionAfterComplete
            classNames={{
              base: 'lg:min-w-[126px]',
            }}
          />
        );
      })}
    </div>
  );
}

export default InactiveQuest;
