import {
  ConnectedChainType,
  useConnectedEvmWalletQuestMutation,
  useGmpContractDeployQuestMutation,
  useGmpSubmitMessageQuestMutation,
} from 'gql';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useEVM } from './useEVM';
import { useLocalStorage } from 'usehooks-ts';

export function useGMPQuestSupport(isEVMWalletConnectionPoints?: boolean) {
  const { evmWalletAddress, evmChain, isEvmWalletConnected } = useEVM();
  const [{ accessToken }] = useCookies(['accessToken']);
  const [savedEVMWalletAddress, setSavedEvmWalletAddress] = useLocalStorage(
    'savedEVMWalletAddress',
    ''
  );
  const [connectedEvmWalletQuest] = useConnectedEvmWalletQuestMutation();
  const [gmpSubmitMessageSepolia, { loading: gmpSubmitMessageLoadingSepolia }] =
    useGmpSubmitMessageQuestMutation();
  const [gmpSubmitMessageShibuya, { loading: gmpSubmitMessageLoadingShibuya }] =
    useGmpSubmitMessageQuestMutation();
  const [gmpContractDeploySepolia, { loading: gmpContractDeployLoadingSepolia }] =
    useGmpContractDeployQuestMutation();
  const [gmpContractDeployShibuya, { loading: gmpContractDeployLoadingShibuya }] =
    useGmpContractDeployQuestMutation();

  useEffect(() => {
    if (isEvmWalletConnected && savedEVMWalletAddress !== evmWalletAddress && evmWalletAddress) {
      connectedEvmWalletQuest({
        variables: {
          walletAddress: evmWalletAddress,
          accessToken,
        },
        onCompleted(data) {
          if (data.connectedEvmWalletQuest) {
            setSavedEvmWalletAddress(evmWalletAddress);
          }
        },
        refetchQueries: ['UserPoints'],
      });
    }
  }, [
    isEvmWalletConnected,
    evmWalletAddress,
    connectedEvmWalletQuest,
    accessToken,
    evmChain,
    isEVMWalletConnectionPoints,
  ]);

  const handleGmpContractDeploy = async ({
    connectedChain,
    contractAddress,
    onError,
    onSuccess,
  }: {
    connectedChain: ConnectedChainType;
    contractAddress: string;
    onError: (error: string) => void;
    onSuccess: () => void;
  }) => {
    if (evmWalletAddress) {
      const deployFunction =
        connectedChain === ConnectedChainType.Sepolia
          ? gmpContractDeploySepolia
          : gmpContractDeployShibuya;
      await deployFunction({
        variables: {
          connectedChain,
          contractAddress,
          evmWalletAddress: evmWalletAddress,
          accessToken,
        },
        onCompleted(data) {
          if (data.GMPContractDeployQuest.error) {
            onError(data.GMPContractDeployQuest.message);
          } else {
            onSuccess();
          }
        },
        refetchQueries: ['UserPoints'],
      });
    }
  };

  const handleGmpSubmitMessage = async ({
    connectedChain,
    transactionHash,
    onError,
    onSuccess,
  }: {
    connectedChain: ConnectedChainType;
    transactionHash: string;
    onError: (error: string) => void;
    onSuccess: () => void;
  }) => {
    if (evmWalletAddress) {
      const submitMessageFunction =
        connectedChain === ConnectedChainType.Sepolia
          ? gmpSubmitMessageSepolia
          : gmpSubmitMessageShibuya;
      await submitMessageFunction({
        variables: {
          connectedChain,
          transactionHash,
          evmWalletAddress: evmWalletAddress,
          accessToken,
        },
        onCompleted(data) {
          if (data.GMPSubmitMessageQuest.error) {
            onError(data.GMPSubmitMessageQuest.message);
          } else {
            onSuccess();
          }
        },
        refetchQueries: ['UserPoints','CompletedDevQuestCount'],
      });
    }
  };

  return {
    handleGmpSubmitMessage,
    handleGmpContractDeploy,
    gmpContractDeployLoadingSepolia,
    gmpContractDeployLoadingShibuya,
    gmpSubmitMessageLoadingSepolia,
    gmpSubmitMessageLoadingShibuya,
  };
}
