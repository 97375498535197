import { Navigate, createHashRouter } from 'react-router-dom';
import * as pages from './pages';
import { pathTo } from 'utils';
import { Layout, AdminRoute, ProtectedRoute } from 'components';
import { ApiProvider } from 'context';
import { QuestPageDataProvider } from 'pages/Quests/useQuestPageData';

export const router = createHashRouter([
  {
    element: (
      <ApiProvider>
        <Layout />
      </ApiProvider>
    ),
    children: [
      {
        path: pathTo('Home'),
        element: <pages.DashBoard />,
      },
      {
        path: pathTo('Game'),
        element: (
          <ProtectedRoute>
            <pages.Game />
          </ProtectedRoute>
        ),
      },
      {
        path: pathTo('Quests'),
        element: (
          <ProtectedRoute>
            <QuestPageDataProvider>
              <pages.Quests />
            </QuestPageDataProvider>
          </ProtectedRoute>
        ),
      },
      {
        path: pathTo('InactiveQuests'),
        element: (
          <ProtectedRoute>
            <QuestPageDataProvider>
              <pages.Quests isInactive />
            </QuestPageDataProvider>
          </ProtectedRoute>
        ),
      },
      {
        path: pathTo('Leaderboard'),
        element: (
          <ProtectedRoute>
            <pages.LeaderboardPage />
          </ProtectedRoute>
        ),
      },
      {
        path: pathTo('ValidatorLeaderboard'),
        element: (
          <ProtectedRoute>
            <pages.ValidatorLeaderboard />
          </ProtectedRoute>
        ),
      },
      {
        path: pathTo('ClosedSessions'),
        element: (
          <ProtectedRoute>
            <pages.ClosedSessions />
          </ProtectedRoute>
        ),
      },
      {
        path: pathTo('AdminPanel'),
        element: (
          <AdminRoute>
            <pages.AdminPanel />
          </AdminRoute>
        ),
      },
      {
        path: pathTo('Session'),
        element: (
          <ProtectedRoute>
            <pages.Voting />
          </ProtectedRoute>
        ),
      },
      {
        path: pathTo('AllViews'),
        element: (
          <ProtectedRoute>
            <pages.AllViews />
          </ProtectedRoute>
        ),
      },
      {
        path: pathTo('FAQ'),
        element: <pages.FAQPage />,
      },
    ],
  },
  {
    path: '/error',
    element: (
      <ApiProvider>
        <pages.LoginError />
      </ApiProvider>
    ),
  },
  {
    path: '/connect-success',
    element: (
      <ApiProvider>
        <pages.ConnectSuccess />
      </ApiProvider>
    ),
  },
  {
    path: pathTo('NotFound'),
    element: <pages.NotFound />,
  },
  {
    path: '/*',
    element: <Navigate to={pathTo('NotFound')} replace />,
  },
]);
