import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import axios from 'axios';
import { useApi, useAuth } from 'context';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useSearchParams } from 'react-router-dom';

import { HTMLAttributes, Setter } from 'types';
import { useLocalStorage } from 'usehooks-ts';
import { classes, customToast, isProduction, pathTo } from 'utils';
import { Button } from './Button';
import { CheckBox } from './CheckBox';
import ForgotPassword from './ForgotPassword';
import { Modal } from './Modal';
import { Tooltip } from './Tooltip';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  CheckIcon,
  EmptyCircleIcon,
  ExclamationMark,
  EyeIcon,
  EyeSlashIcon,
  FilledCircleIcon,
  GoogleIcon,
} from './icons';
import { fpPromise } from 'main';

enum AuthType {
  SignIn = 'signin',
  SignUp = 'signup',
  ForgotPassword = 'forgot-password',
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  showRefresh: boolean;
}

const SignInWithGoogle = () => {
  window.location.href = `${import.meta.env.VITE_BACKEND_ENDPOINT}auth/google/callback`;
};

export function ConnectAccount({ showRefresh }: Props) {
  const [{ accessToken }] = useCookies(['accessToken']);

  const { trackEvent } = useApi();
  const { isSuccessLogin } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isToSAgreed, setIsToSAgreed] = useLocalStorage('IncentivizedTermsOfServiceAgreed', false);
  
  const [isGoogleSignInStarted, setIsGoogleSignInStarted] = useLocalStorage(
    'isGoogleSignInStarted',
    false
  );
  const [isVerificationStarted, setIsVerificationStarted] = useLocalStorage(
    'isVerificationStarted',
    false
  );

  const [error, setError] = useState('');
  const [isFPError, setIsFPError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [isGoogleAuthLoading, setIsGoogleAuthLoading] = useState<boolean>(false);
  const [_, SetIsFP] = useLocalStorage('isFP', '');

  const [password, setPassword] = useState({
    value: '',
    isError: false,
    isTouched: false,
    show: false,
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: '',
    isError: false,
    isTouched: false,
    show: false,
  });
  const [email, setEmail] = useState({
    value: '',
    isError: false,
    isTouched: false,
  });
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);

  const passwordValidations = useMemo(() => {
    if (password.value.length) {
      return {
        length: password.value.length >= 8,
        number: /\d/gm.test(password.value),
        case: /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(password.value),
      };
    }
    return { length: false, number: false, case: false };
  }, [password]);

  const authType = useMemo(() => {
    if (searchParams.get(AuthType.SignIn) !== null) return AuthType.SignIn;
    else if (searchParams.get(AuthType.SignUp) !== null) return AuthType.SignUp;
    else if (searchParams.get(AuthType.ForgotPassword) !== null) return AuthType.ForgotPassword;
  }, [searchParams]);

  const isOpen = useMemo(() => {
    if (isSuccessLogin && authType && searchParams.get(authType) !== null) {
      searchParams.delete(authType);
      searchParams.delete('referral');
      setSearchParams(searchParams);
      customToast('Already logged in');
      return false;
    }

    return authType && searchParams.get(authType) !== null;
  }, [searchParams]);

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        authType && searchParams.set(authType, '');
      } else {
        authType && searchParams.delete(authType);
        searchParams.delete('referral');
        SetIsFP('');
      }
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams, authType, SetIsFP]
  );

  const validate = useCallback(() => {
    const emailRegex = /[a-z0-9]+@[a-z0-9]+\.[a-z]{2,3}/;
    const isEmailValid = emailRegex.test(email.value);
    setEmail((prev) => {
      return {
        ...prev,
        isTouched: true,
        isError: !isEmailValid,
      };
    });
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isPasswordValid = passwordRegex.test(password.value);
    setPassword((prev) => {
      return {
        ...prev,
        isTouched: true,
        isError: !isPasswordValid,
      };
    });

    if (authType === AuthType.SignUp) {
      setConfirmPassword((prev) => {
        return {
          ...prev,
          isTouched: true,
          isError: password.value !== confirmPassword.value,
        };
      });
      return isEmailValid && isPasswordValid && password.value === confirmPassword.value;
    }

    return isEmailValid && isPasswordValid;
  }, [email, password, confirmPassword]);

  const handleSignUp = async () => {
    trackEvent('gtm_login', {
      action: 'sign_up_form_submited',
    });
    if (validate()) {
      setLoading(true);
      try {
        const isSignUpEligible = await axios.post(
          `${import.meta.env.VITE_BACKEND_ENDPOINT}auth/signUpEligible`,
          {
            email: email.value,
            password: password.value,
          }
        );
        if (isSignUpEligible.status === 200) {
          const FPResult = await (await fpPromise).get().catch(() => setIsFPError(true));
          // : { visitorId: Math.floor(Math.random() * 10000000).toString() };
          if (FPResult) {
            const data = await axios.post(`${import.meta.env.VITE_BACKEND_ENDPOINT}auth/signUp`, {
              token: isSignUpEligible.data.token,
              vId: FPResult.visitorId,
              captchaToken,
            });

            if (data.status === 200) {
              !isToSAgreed && setIsToSAgreed(true);
              setIsEmailSent(true);
              setIsVerificationStarted(true);
              SetIsFP('true');
              trackEvent('gtm_login', {
                action: 'sign_up_verify_email_sended',
              });
            }
          }
        }
      } catch (e: any) {
        setError(e?.response?.data?.message);
        trackEvent('gtm_login', {
          action: 'sign_up_verify_email_faliure',
          error: e?.response?.data,
        });
      }
      setLoading(false);
    }
  };

  const [title, subTitle, content] = useMemo(() => {
    if (authType === AuthType.SignUp) {
      if (isEmailSent) {
        return [
          <div className="flex items-center gap-2" key={'title'}>
            <CheckIcon className="p-2 h-10 w-10 bg-black-100 rounded-full" /> Confirm your email
          </div>,
          <div className="mt-3" key={'subTitle'}>
            Thank you for signing up for Analog’s Incentivized Testnet! Please verify your account
            by clicking on the link sent to your email.
          </div>,
        ];
      }

      return [
        'Sign Up',
        "Sign up now to join Analog's Incentivized Testnet.",
        <>
          <div className="flex flex-col gap-4">
            <div
              className={classes(
                'bg-black w-full flex justify-between gap-[13px] md:rounded-lg rounded-md md:py-[9px] py-2 px-3 border-[0.5px] border-solid border-[#393939] text-sm shadow-primary-shadow',
                email.isError && email.isTouched && 'border-rose-500'
              )}
            >
              <input
                className="w-full autofill:bg-clip-text autofill:shadow-[inset 0_0_20px_20px_#23232300] autofill:[-webkit-text-fill-color:#fff]"
                placeholder="Email"
                autoComplete="new-password"
                value={email.value}
                onChange={(e) => {
                  error && setError('');
                  setEmail((prev) => ({
                    ...prev,
                    value: e.target.value.replaceAll(' ', ''),
                    isTouched: false,
                  }));
                }}
              />
              {email.isError && email.isTouched && (
                <div className="flex items-center gap-1 text-rose-500">
                  <InformationCircleIcon className="w-5 h-5" />
                  <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                    Wrong email
                  </p>
                </div>
              )}
            </div>
            <div
              className={classes(
                'bg-black w-full flex justify-between gap-[13px] md:rounded-lg rounded-md md:py-[9px] py-2 px-3 border-[0.5px] border-solid border-[#393939] text-sm shadow-primary-shadow',
                password.isTouched && password.isError && 'border-rose-500'
              )}
            >
              <input
                className="w-full autofill:bg-clip-text autofill:shadow-[inset 0_0_20px_20px_#23232300] autofill:[-webkit-text-fill-color:#fff]"
                placeholder="Password"
                autoComplete="new-password"
                value={password.value}
                type={password.show ? 'text' : 'password'}
                onChange={(e) => {
                  error && setError('');
                  setPassword((prev) => ({
                    ...prev,
                    value: e.target.value.replaceAll(' ', ''),
                    isTouched: false,
                  }));
                }}
              />
              <button
                type="button"
                onClick={() => setPassword((prev) => ({ ...prev, show: !prev.show }))}
              >
                {password.show ? (
                  <EyeSlashIcon className="md:h-5 md:w-5 h-[18px] w-[18px]" />
                ) : (
                  <EyeIcon className="md:h-5 md:w-5 h-[18px] w-[18px]" stroke="#919191" />
                )}
              </button>
            </div>
            {password.value.length !== 0 && (
              <div
                className={classes(
                  'bg-black w-full flex justify-between gap-[13px] md:rounded-lg rounded-md md:py-[9px] py-2 px-3 border-[0.5px] border-solid border-[#393939] text-sm shadow-primary-shadow',
                  confirmPassword.isTouched && confirmPassword.isError && 'border-rose-500'
                )}
              >
                <input
                  className="w-full autofill:bg-clip-text autofill:shadow-[inset 0_0_20px_20px_#23232300] autofill:[-webkit-text-fill-color:#fff]"
                  placeholder="Confirm Password"
                  autoComplete="new-password"
                  value={confirmPassword.value}
                  type={confirmPassword.show ? 'text' : 'password'}
                  onChange={(e) => {
                    error && setError('');
                    setConfirmPassword((prev) => ({
                      ...prev,
                      value: e.target.value.replaceAll(' ', ''),
                      isTouched: false,
                    }));
                  }}
                />
                <button
                  type="button"
                  onClick={() => setConfirmPassword((prev) => ({ ...prev, show: !prev.show }))}
                >
                  {confirmPassword.show ? (
                    <EyeSlashIcon className="md:h-5 md:w-5 h-[18px] w-[18px]" />
                  ) : (
                    <EyeIcon className="md:h-5 md:w-5 h-[18px] w-[18px]" stroke="#919191" />
                  )}
                </button>
              </div>
            )}
            {password.value.length !== 0 && confirmPassword.isTouched && confirmPassword.isError && (
              <div className="flex items-center gap-1 text-rose-500 -mt-3 mb-1">
                <InformationCircleIcon className="w-5 h-5" />
                <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                  Password doesn’t match
                </p>
              </div>
            )}
            {password.value.length !== 0 && (
              <div className="flex flex-col gap-2 -mt-1 text-gray text-xs">
                <div
                  className={classes(
                    'flex items-center gap-[6px]',
                    passwordValidations.length && 'text-[#23CA3E]'
                  )}
                >
                  {passwordValidations.length ? <FilledCircleIcon /> : <EmptyCircleIcon />}
                  <span>At least 8 characters</span>
                </div>
                <div
                  className={classes(
                    'flex items-center gap-[6px]',
                    passwordValidations.number && 'text-[#23CA3E]'
                  )}
                >
                  {passwordValidations.number ? <FilledCircleIcon /> : <EmptyCircleIcon />}
                  <span>At least 1 number</span>
                </div>
                <div
                  className={classes(
                    'flex items-center gap-[6px]',
                    passwordValidations.case && 'text-[#23CA3E]'
                  )}
                >
                  {passwordValidations.case ? <FilledCircleIcon /> : <EmptyCircleIcon />}
                  <span>Both upper and lower case letters</span>
                </div>
              </div>
            )}
            {isTermsAgreed && email.value && password.value && (
              <ReCAPTCHA
                theme="dark"
                sitekey={import.meta.env.VITE_WATCH_GOOGLE_CAPTCHA_SITEKEY}
                onChange={(val) => setCaptchaToken(val)}
              />
            )}
            <div className="flex gap-2 items-center mb-2">
              <CheckBox
                withGradient
                isChecked={isTermsAgreed}
                onClick={() => setIsTermsAgreed(!isTermsAgreed)}
                className="cursor-pointer"
              />
              <div className="text-sm">
                I accept{' '}
                <a
                  href={window.location.origin + '/terms-of-service.pdf'}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer"
                >
                  Terms of Service
                </a>{' '}
                &{' '}
                <a
                  href={window.location.origin + '/privacy-policy.pdf'}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
            <Button
              variant="primary"
              isDisabled={
                !email.value ||
                !password.value ||
                !confirmPassword.value ||
                !isTermsAgreed ||
                !captchaToken
              }
              isLoading={loading}
              className="uppercase"
              onClick={handleSignUp}
            >
              Sign Up
            </Button>
          </div>

          {error && (
            <div className="mt-[10px] text-[#FF3A5D] text-xs flex flex-row items-center gap-[6px] justify-center">
              <ExclamationMark />
              <span dangerouslySetInnerHTML={{ __html: error }} />
            </div>
          )}

          {isFPError && (
            <div className="mt-[10px] text-[#FF3A5D] text-xs flex flex-row items-center gap-[6px] justify-center">
              <ExclamationMark />
              <span>
                Browser issue detected. Please check your extensions or use another browser.
              </span>
            </div>
          )}

          <div className="relative flex items-center justify-center p-4">
            <div className="bg-black px-3 text-neutral-400 text-sm leading-tight w-max after:w-[calc(50%_-_20px)] after:right-0 after:h-[1px] after:bg-neutral-800 after:absolute after:top-1/2 before:w-[calc(50%_-_20px)] before:h-[1px] before:bg-neutral-800 before:absolute before:top-1/2 before:left-0 italic">
              or
            </div>
          </div>
          <div className="relative" data-tooltip-id="sign-with-google-blocked">
            <Button
              variant="plain"
              className="!text-white uppercase w-full border border-solid border-steel-gray rounded-lg relative"
              classNames={{ container: 'gap-[6px]' }}
              isDisabled={!isTermsAgreed}
              isLoading={isGoogleAuthLoading}
              onClick={() => {
                trackEvent('gtm_login', {
                  action: 'sign_in_with_google_started',
                });
                !isToSAgreed && setIsToSAgreed(true);
                setIsGoogleAuthLoading(true);
                setIsGoogleSignInStarted(true);
                SignInWithGoogle();
              }}
            >
              <div className="p-1 bg-white rounded-full">
                <GoogleIcon />
              </div>
              Sign up with Google
            </Button>
            {!isTermsAgreed && (
              <Tooltip
                delay={500}
                id="sign-with-google-blocked"
                content="Please accept Terms of Use and Privacy Policy above to continue"
                classNames="max-w-[80vw] break-words"
              />
            )}
          </div>
          <div className="text-[14px] font text-center mt-6 -mb-2 italic font-['Chakra_Petch']">
            Already have an account? Sign in{' '}
            <Link
              to={pathTo('SignIn')}
              className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer pr-[2px]"
            >
              here
            </Link>
          </div>
        </>,
      ];
    } else if (authType === AuthType.SignIn) {
      return [
        'Sign In',
        "Sign in to get started with Analog's Incentivized Testnet.",
        <>
          <div className="flex flex-col gap-4">
            <div
              className={classes(
                'bg-black w-full flex justify-between gap-[13px] md:rounded-lg rounded-md md:py-[9px] py-2 px-3 border-[0.5px] border-solid border-[#393939] text-sm shadow-primary-shadow',
                email.isError && email.isTouched && 'border-rose-500'
              )}
            >
              <input
                className="w-full autofill:bg-clip-text autofill:shadow-[inset 0_0_20px_20px_#23232300] autofill:[-webkit-text-fill-color:#fff]"
                placeholder="Email"
                value={email.value}
                onChange={(e) => {
                  error && setError('');
                  setEmail((prev) => ({
                    ...prev,
                    value: e.target.value.replaceAll(' ', ''),
                    isTouched: false,
                  }));
                }}
              />
              {email.isError && email.isTouched && (
                <div className="flex items-center gap-1 text-rose-500">
                  <InformationCircleIcon className="w-5 h-5" />
                  <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                    Wrong email
                  </p>
                </div>
              )}
            </div>
            <div
              className={classes(
                'bg-black w-full flex justify-between gap-[13px] md:rounded-lg rounded-md md:py-[9px] py-2 px-3 border-[0.5px] border-solid border-[#393939] text-sm shadow-primary-shadow',
                password.isTouched && password.isError && 'border-rose-500'
              )}
            >
              <input
                className="w-full autofill:bg-clip-text autofill:shadow-[inset 0_0_20px_20px_#23232300] autofill:[-webkit-text-fill-color:#fff]"
                placeholder="Password"
                value={password.value}
                type={password.show ? 'text' : 'password'}
                onChange={(e) => {
                  error && setError('');
                  setPassword((prev) => ({
                    ...prev,
                    value: e.target.value.replaceAll(' ', ''),
                    isTouched: false,
                  }));
                }}
              />{' '}
              <button
                type="button"
                onClick={() => setPassword((prev) => ({ ...prev, show: !prev.show }))}
              >
                {password.show ? (
                  <EyeSlashIcon className="md:h-5 md:w-5 h-[18px] w-[18px]" />
                ) : (
                  <EyeIcon className="md:h-5 md:w-5 h-[18px] w-[18px]" stroke="#919191" />
                )}
              </button>
            </div>
            {password.isTouched && password.isError && (
              <div className="flex items-center gap-1 text-rose-500 -mt-3 mb-1">
                <InformationCircleIcon className="w-5 h-5" />
                <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight flex break-word">
                  Password should contain:
                  {!passwordValidations.length && ' At least 8 characters'}
                  {!passwordValidations.length && !passwordValidations.case && ','}
                  {!passwordValidations.case && ' Both upper and lower case letters'}
                  {(!passwordValidations.case || !passwordValidations.length) &&
                    !passwordValidations.number &&
                    ','}
                  {!passwordValidations.number && ' At least 1 number'}
                </p>
              </div>
            )}

            <Link
              to={pathTo('ForgotPassword')}
              className="w-fit pr-2 text-sm font-['Chakra_Petch'] font-light bg-primary-gradient bg-clip-text text-transparent cursor-pointer italic -mb-1 -mt-2"
              onClick={() => setError('')}
            >
              Forgotten your password?
            </Link>
            {password.value && email.value && (
              <ReCAPTCHA
                theme="dark"
                sitekey={import.meta.env.VITE_WATCH_GOOGLE_CAPTCHA_SITEKEY}
                onChange={(val) => setCaptchaToken(val)}
              />
            )}
            <Button
              variant="primary"
              isDisabled={!email.value || !password.value || !captchaToken}
              isLoading={loading}
              onClick={async () => {
                trackEvent('gtm_login', {
                  action: 'sign_in_form_submitted',
                });
                if (validate()) {
                  setLoading(true);
                  try {
                    const data = await axios.post(
                      `${import.meta.env.VITE_BACKEND_ENDPOINT}auth/signIn`,
                      {
                        userType: 'voter',
                        email: email.value,
                        password: password.value,
                        captchaToken,
                      },
                      { withCredentials: true }
                    );
                    if (data.data == 'SuccessFully Signed In') {
                      trackEvent('gtm_login', {
                        action: 'sign_in_successfully',
                      });
                    } else {
                      trackEvent('gtm_login', {
                        action: 'sign_in_failure',
                      });
                    }
                  } catch (e: any) {
                    setError(e?.response?.data?.message);
                    trackEvent('gtm_login', {
                      action: 'sign_in_failure',
                    });
                  }
                  setLoading(false);
                }
              }}
            >
              Sign In
            </Button>
          </div>

          {error && (
            <div className="mt-[10px] text-[#FF3A5D] text-xs flex flex-row items-center gap-[6px] justify-center">
              <ExclamationMark />
              <span dangerouslySetInnerHTML={{ __html: error }} />
            </div>
          )}

          <div className="relative flex items-center justify-center p-4">
            <div className="bg-black px-3 text-neutral-400 text-sm leading-tight w-max after:w-[calc(50%_-_20px)] after:right-0 after:h-[1px] after:bg-neutral-800 after:absolute after:top-1/2 before:w-[calc(50%_-_20px)] before:h-[1px] before:bg-neutral-800 before:absolute before:top-1/2 before:left-0 italic">
              or
            </div>
          </div>
          <div>
            <Button
              variant="plain"
              className="!text-white uppercase w-full border border-solid border-steel-gray rounded-lg"
              classNames={{ container: 'gap-[6px]' }}
              isLoading={isGoogleAuthLoading}
              onClick={() => {
                trackEvent('gtm_login', {
                  action: 'sign_in_with_google_started',
                });
                setIsGoogleAuthLoading(true);
                setIsGoogleSignInStarted(true);
                SignInWithGoogle();
              }}
            >
              <div className="p-1 bg-white rounded-full">
                <GoogleIcon />
              </div>
              Sign In with google
            </Button>
          </div>
        </>,
      ];
    }

    return ['', '', ''];
  }, [{ ...password }, { ...email }, error, isEmailSent, authType]);

  useEffect(() => {
    if (!isOpen) {
      setIsEmailSent(false);
      setIsTermsAgreed(false);
      setEmail({
        value: '',
        isError: false,
        isTouched: false,
      });
      setPassword({ value: '', isError: false, isTouched: false, show: false });
      setConfirmPassword({ value: '', isError: false, isTouched: false, show: false });
      setError('');
      setLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSuccessLogin && accessToken && isOpen) {
      customToast('You have successfully signed in!');
      setIsOpen(false);
      setIsEmailSent(false);
    }
  }, [isSuccessLogin]);

  useEffect(() => {
    if (isSuccessLogin && accessToken && isGoogleSignInStarted) {
      if (searchParams.get('sign-in-success') !== null) {
        customToast('You have successfully signed in!');
        trackEvent('gtm_login', {
          action: 'sign_in_with_google_successfully',
        });
      } else {
        trackEvent('gtm_login', {
          action: 'sign_in_with_google_failure',
        });
      }
      setIsGoogleSignInStarted(false);
    }
    if (isSuccessLogin && accessToken && isVerificationStarted && window.location.hash == '#/') {
      trackEvent('gtm_login', {
        action: 'sign_up_successfully',
      });
      setIsVerificationStarted(false);
    }
  }, [isSuccessLogin, isGoogleSignInStarted, isVerificationStarted]);

  return (
    <Modal
      isOpen={!!isOpen}
      setIsOpen={setIsOpen as Setter<boolean>}
      className="w-full overflow-auto z-50 bg-black md:p-8 p-6 scrollbar-white"
      isCloseInside
    >
      {showRefresh ? (
        <div className="flex flex-col items-center justify-center text-center">
          <span className="text-2xl mt-[31px] mb-5">Something went wrong</span>
          <Button
            className="p-[12px_24px] flex rounded-full h-[46px]"
            variant="plain"
            style={{
              background:
                'linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(95deg, #5D3EF8 -12.23%, #BA4CD6 117.24%)',
            }}
            onClick={() => {
              window.location?.reload();
            }}
          >
            <span className="text-[#010314] text-base leading-[22px]">Refresh</span>
          </Button>
        </div>
      ) : authType && authType === AuthType.ForgotPassword ? (
        <ForgotPassword />
      ) : (
        <div className="w-full h-fit">
          <div className="sm:text-xl text-lg">{title}</div>
          <div className="text-gray text-sm mt-1 mb-4">{subTitle}</div>
          {content}
        </div>
      )}
    </Modal>
  );
}
