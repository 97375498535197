import { classes } from 'utils';
import { GreenRightIcon, TickWithBox } from './icons';
import { Skeleton } from './Skeleton';
import { QuestTask } from 'types';
import { Button } from './Button';

export function ProofOfHumanityTaskCard({
  isCompleted,
  task,
}: {
  task: QuestTask;
  isCompleted: boolean;
}) {
  return (
    <div className={classes('relative')}>
      <div
        className={classes(
          'absolute w-full h-full top-0 left-0 -z-10',
          isCompleted ? "bg-[url('/CapaBGGreen.png')]" : "bg-[url('/CapaBG.png')]"
        )}
      />
      <div
        className={classes(
          'flex min-h-[90px] border-solid border-steel-gray shadow-primary-shadow rounded-lg border-[0.5px] backdrop-blur-lg',
          isCompleted &&
            'bg-[linear-gradient(0deg,_var(--tw-gradient-stops))] from-[#21b36813_0%] to-[#21b36813_100%] border-[#21B368] border-[0.5px]'
        )}
      >
        <div
          className={classes(
            'md:px-6 px-4 sm:py-4 py-5 flex sm:justify-center items-center gap-4 rounded-[7px_0px_0px_7px] lg:flex-row flex-col'
          )}
        >
          <TickWithBox isGreen={isCompleted} />
        </div>
        <div className="w-full">
          <div className="flex lg:flex-row flex-col justify-between lg:items-center items-start gap-[11px] xl:gap-0  py-4 pr-6 h-full w-full">
            <div className="flex flex-col lg:pb-0 xl:pr-6">
              <div className=" text-white md:text-base text-sm font-normal font-['Chakra Petch'] md:leading-normal">
                <Skeleton.Loader className="max-w-[226px] md:min-w-[200px] min-w-[100px] h-[22px]">
                  {task.title}
                </Skeleton.Loader>
              </div>
              <Skeleton.Loader className="max-w-[353px] md:min-w-[300px] min-w-[100px] h-[18px]">
                <div className="text-gray text-xs text-justify">{task.description}</div>
              </Skeleton.Loader>
            </div>
            {isCompleted ? (
              <Button
                variant="plain"
                classNames={{
                  base: 'max-h-10 after:hidden h-[unset] [background:linear-gradient(140deg,_#d274f70d_16.37%,_#9a74f70d_50.02%)#000] border border-solid border-[#21B368] rounded-lg h-[unset] px-3 !py-[9px] lg:min-w-[172px] w-full lg:max-w-[172px] cursor-default !bg-none',
                  container: 'flex items-center lg:gap-[6px] gap-1',
                }}
              >
                <GreenRightIcon />
                <div className="text-center text-[#21B368] font-text-bold md:text-sm text-xs md:leading-[22px] leading-[18px] uppercase">
                  Completed
                </div>
              </Button>
            ) : (
              <div className="flex xl:gap-4 gap-3 self-center xl:flex-row flex-col lg:w-fit w-full">
                {task.taskButton}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
