import React from 'react';
import { ClassNames, HTMLAttributes } from 'types';
import { classes } from 'utils';
import { Skeleton } from './Skeleton';
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';

interface Props extends HTMLAttributes<HTMLDivElement> {
  tag?: string | React.ComponentType<HTMLAttributes<unknown>>;
  classNames?: ClassNames<'header' | 'accessory'>;
  accessory?: React.ReactNode;
}

export function Header({ children, className, classNames, tag: Tag = 'h3', accessory }: Props) {
  const navigate = useNavigate();
  return (
    <div className={classes('text-white text-2xl', className, classNames?.base)}>
      <Skeleton.Loader className="w-[120px] h-8">
        {!!children && <Tag className={classes('uppercase', classNames?.header)}>{children}</Tag>}
      </Skeleton.Loader>
      {accessory && (
        <Skeleton.Loader
          className={classes(
            'flex mb-4 space-x-3 [&>*]:h-full [&>*]:flex-1 md:!w-[250px] w-full md:h-10 h-9',
            classNames?.accessory
          )}
        >
          <div
            className={classes(
              'flex mb-4 space-x-3 [&>*]:h-full w-full sm:w-auto [&>*]:flex-1',
              classNames?.accessory
            )}
          >
            <Button
              variant="plain"
              className={classes(
                'uppercase',
                'flex justify-center items-center text-sm bg-primary-gradient bg-clip-text text-transparent leading-6 font-normal'
              )}
              onClick={() => {
                navigate('/quests/inactive');
              }}
            >
              archived Quests
            </Button>
            {accessory}
          </div>
        </Skeleton.Loader>
      )}
    </div>
  );
}
