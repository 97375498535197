export function BadgeIcon({ isGreen }: { isGreen?: boolean }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect
        width="40"
        height="40"
        rx="8"
        fill={isGreen ? '#23CA3E' : '#1C1C1C'}
        fillOpacity={isGreen ? 0.1 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5564 9.02954C19.4185 9.0653 12.1298 12.3925 11.865 12.5405C11.5945 12.6918 11.2813 13.0379 11.1289 13.354L11.0181 13.5837L11.0048 17.9569C10.99 22.8209 10.9907 22.8358 11.2965 23.7771C11.3842 24.047 11.5335 24.4266 11.6283 24.6206C12.0182 25.4186 12.631 26.2087 13.2675 26.7338C13.7551 27.1362 19.1677 30.8221 19.4164 30.9212C19.6037 30.9958 19.7326 31.0102 20.0696 30.9941C20.4091 30.9779 20.5362 30.9493 20.7335 30.8448C21.1735 30.6117 26.5475 26.8909 26.8622 26.6015C27.5215 25.9951 28.0468 25.2776 28.3973 24.5043C28.6195 24.0143 28.753 23.6113 28.8789 23.051C28.9728 22.6329 28.9765 22.4736 28.9924 18.2489C29.0083 13.9747 29.0066 13.8725 28.9143 13.5633C28.8026 13.1892 28.5529 12.8348 28.2504 12.6211C28.1308 12.5366 26.3881 11.7156 24.3779 10.7967C22.3676 9.87785 20.6414 9.09634 20.542 9.06011C20.3694 8.99725 19.7547 8.97817 19.5564 9.02954ZM23.9153 17.0096C23.9814 17.0604 24.074 17.1697 24.121 17.2524C24.2216 17.4293 24.2332 17.7752 24.1443 17.9492C24.0675 18.0999 19.6101 22.8762 19.4164 23.0155C19.198 23.1726 18.9702 23.1959 18.733 23.0853C18.6021 23.0243 18.0667 22.5455 17.2261 21.7379C16.5091 21.0488 15.8926 20.4266 15.8563 20.3552C15.8128 20.2697 15.797 20.1293 15.81 19.9437C15.8268 19.7045 15.8531 19.6373 15.9833 19.4988C16.0692 19.4075 16.2168 19.3148 16.3187 19.2882C16.5202 19.2355 16.7726 19.2773 16.933 19.39C16.9901 19.4301 17.4656 19.8756 17.9895 20.3799C18.5135 20.8843 18.9573 21.2969 18.9756 21.2969C18.994 21.2969 19.917 20.3252 21.0267 19.1376C22.1363 17.95 23.0994 16.9564 23.1666 16.9297C23.2339 16.9029 23.331 16.8733 23.3823 16.8639C23.5262 16.8374 23.7821 16.9074 23.9153 17.0096Z"
        fill={isGreen ? '#23CA3E' : '#919191'}
      />
    </svg>
  );
}
