import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { Button, Skeleton } from 'components';
import { GalxeQuestType, useClaimGalxePointsMutation, useFetchGalaxyQuestPointsQuery } from 'gql';

type GalaxeQuestAdditionalDescProp = {
  evmWalletAddress?: string;
  accessToken?: string;
  questType: GalxeQuestType;
  trackEvent: (eventName: string, eventData?: object) => void;
};
const GalaxeQuestAdditionalDesc = ({
  evmWalletAddress,
  accessToken,
  questType,
  trackEvent,
}: GalaxeQuestAdditionalDescProp) => {
  const [claimGalaxePoints, { data, loading: claimPointsLoading, error: claimPointsError }] =
    useClaimGalxePointsMutation({
      fetchPolicy: 'no-cache',
    });

  const { data: galaxyQuestPoints, loading: galaxyQuestPointsLoading } =
    useFetchGalaxyQuestPointsQuery({
      variables: {
        evmWalletAddress: evmWalletAddress as string,
        accessToken,
        questType,
      },
      skip: !evmWalletAddress || !accessToken,
      fetchPolicy: 'cache-first',
    });

  return (
    <>
      <div className="flex gap-4 lg:flex-row flex-col p-4 pl-6">
        <Skeleton.Loader className="h-10 mt-auto w-[150px]">
          <div className="flex flex-col gap-2 ">
            <div className="md:text-base text-sm">Claimed points on Galxe</div>
            <div className="p-[10px] border border-solid border-[#393939] justify-between flex-row flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px]">
              <div className="truncate w-full whitespace-pre-line line-clamp-1 break-all">
                {galaxyQuestPoints?.fetchGalaxyQuestPoints?.claimedPointsFromGalaxe || 0} pts
              </div>
            </div>
          </div>
          <Button
            variant="primary"
            className="mt-auto"
            isDisabled={
              !evmWalletAddress ||
              !galaxyQuestPoints?.fetchGalaxyQuestPoints?.canClaimMore ||
              data?.ClaimGalxePoints
            }
            isLoading={claimPointsLoading || galaxyQuestPointsLoading}
            onClick={() => {
              if (evmWalletAddress && accessToken) {
                claimGalaxePoints({
                  variables: { evmWalletAddress, accessToken, questType },
                  refetchQueries: ['UserPoints', 'CheckGalaxyQuestCompleted'],
                });
                trackEvent('quest_clicks', {
                  communityQuest: 'Claim Galaxe Points',
                });
              }
            }}
          >
            Claim
          </Button>
        </Skeleton.Loader>
      </div>
      {claimPointsError && (
        <div className="flex [flex-basis:100%] items-center gap-1 text-rose-500 px-3 pb-3">
          <InformationCircleIcon className="w-5 h-5" />
          <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
            {claimPointsError.message}
          </p>
        </div>
      )}
      {data?.ClaimGalxePoints && (
        <div className="flex [flex-basis:100%] items-center gap-1 text-green-500 px-3 pb-3">
          <CheckCircleIcon className="w-5 h-5" />
          <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
            Claimed {data?.ClaimGalxePoints} points
          </p>
        </div>
      )}
    </>
  );
};

export default GalaxeQuestAdditionalDesc;
