import {
  Button,
  DmailIcon,
  GalxeIcon,
  QuaereIcon,
  TalismanIcon,
  Tooltip,
  ZealyIcon,
} from 'components';
import {
  DeveloperQuestSubType,
  GalxeQuestType,
  useClaimQuaerePointsMutation,
  useClaimZealyQuestPointsMutation,
  useVerifyDmailSendEmailQuestMutation,
  useVerifyProofOfHumanityMutation,
} from 'gql';
import { RewardType } from './useDeveloperQuests';
import { useCookies } from 'react-cookie';
import { useEVM } from './useEVM';
import { useApi } from 'context';
import { customToast, isProduction, pathTo } from 'utils';
import { HTMLAttributes } from 'types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MouseEvent, useState } from 'react';
import GalaxeQuestAdditionalDesc from 'pages/Quests/GalaxeQuestAdditionalDesc';

export interface QuestButtonProps extends HTMLAttributes<HTMLButtonElement> {
  handleClick?: (event?: React.MouseEvent<HTMLButtonElement>, isClicked?: boolean) => void;
  isLoading?: boolean;
  buttonTextAfterClick?: string | null;
  inactive?: boolean;
}

export const QuestTaskButton = (props: QuestButtonProps) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <Button
      variant="light"
      classNames={{
        base: `h-[unset] px-3 !py-[9px] lg:!w-[172px] !w-full lg:!max-w-[172px] ${
          props.inactive && 'brightness-150'
        }`,
        container: 'md:text-sm text-xs md:leading-[22px] leading-[18px]',
      }}
      isLoading={props.isLoading}
      onClick={(e) => {
        props.handleClick && props.handleClick(e, isClicked);
        props.buttonTextAfterClick && setIsClicked(true);
      }}
    >
      {props.buttonTextAfterClick && isClicked ? props.buttonTextAfterClick : props.children}
    </Button>
  );
};

export const useCommunityQuests = () => {
  const [{ accessToken }] = useCookies(['accessToken']);
  const { evmWalletAddress, open } = useEVM();
  const { account, trackEvent, userPoints, fetchUserPoints, fetchUser } = useApi();
  const [_, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [verifyProofOfHumanity, { loading: verifyProofOfHumanityLoading }] =
    useVerifyProofOfHumanityMutation({
      fetchPolicy: 'no-cache',
      onCompleted() {
        customToast('Proof of humanity verified!');
        trackEvent('Proof of humanity Verified');
        fetchUserPoints();
        fetchUser();
      },
      onError() {
        window.open('https://www.intract.io/proof-of-humanity?pohReferralCode=ANALOG', '_blank');
      },
    });

  const [verifyDmailSendEmailQuest, { loading: verifyDmailSendEmailLoading }] =
    useVerifyDmailSendEmailQuestMutation({
      fetchPolicy: 'no-cache',
    });

  const [claimQuaereQuest, { loading: claimQuaereQuestLoading }] = useClaimQuaerePointsMutation({
    fetchPolicy: 'no-cache',
  });
  const [claimZealyQuest, { loading: claimZealyQuestLoading }] = useClaimZealyQuestPointsMutation({
    fetchPolicy: 'no-cache',
  });

  const handleSentEmailOnDmailClaim = (_?: MouseEvent<HTMLButtonElement>, isClicked?: boolean) => {
    if (account?.walletAddress) {
      verifyDmailSendEmailQuest({
        variables: { accessToken },
        onCompleted() {
          fetchUserPoints();
        },
        onError(err) {
          if (!isClicked) {
            window.open('https://mail.dmail.ai', '_blank');
          } else {
            customToast(`Verification failed: ${err.message}`, true);
          }
        },
      });
      trackEvent('quest_clicks', {
        communityQuest: 'Dmail Quest Claim',
      });
    } else {
      setSearchParams({ 'connect-wallet': 'true' });
    }
  };

  const handleQuaereQuestClaim = (_?: MouseEvent<HTMLButtonElement>, isClicked?: boolean) => {
    claimQuaereQuest({
      variables: { accessToken },
      onCompleted() {
        fetchUserPoints();
      },
      onError(err) {
        if (!isClicked) {
          window.open('https://www.quaere.ai/', '_blank');
        } else {
          customToast(`Verification failed: ${err.message}`, true);
        }
      },
    });
    trackEvent('quest_clicks', {
      communityQuest: 'Dmail Quest Claim',
    });
  };

  const handleZealyQuestClaim = () => {
    if (account?.connectedDiscordServer) {
      claimZealyQuest({
        variables: { accessToken },
        onCompleted() {
          fetchUserPoints();
          fetchUser();
        },
        onError(err) {
          customToast(err.message, true);
        },
      });
      trackEvent('quest_clicks', {
        communityQuest: 'Zealy Quest Claim',
      });
    } else {
      window.location.href = `${import.meta.env.VITE_BACKEND_ENDPOINT}auth/verifyDiscordGuild`;
    }
  };

  return [
    {
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.ProofOfHumanity,
          title: "Prove you're a human with Proof of Humanity!",
          description:
            "Collect medallions with Intract to prove you're human and earn extra rewards at the end of our incentivized testnet.",
          primaryPoints: 'xATP',
          hideCompletedButton: true,
          taskButton: (
            <QuestTaskButton
              isLoading={verifyProofOfHumanityLoading}
              handleClick={() => {
                trackEvent('quest_clicks', {
                  communityQuest: 'Proof of Humanity',
                });
                if (evmWalletAddress) {
                  verifyProofOfHumanity({
                    variables: { evmWalletAddress: evmWalletAddress as string, accessToken },
                  });
                } else {
                  open();
                }
              }}
            >
              {!evmWalletAddress ? 'Connect Wallet' : 'Verify'}
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.SignInWithEmail,
          title: 'Sign in with email',
          description: 'You can sign in with Google or create a new account with any email.',
          primaryPoints: '1 ATP',
        },
      ],
    },
    {
      heading: 'Galxe',
      headingIcon: (
        <div className="h-8 w-8 bg-[#ffffff1a] rounded-md flex justify-center items-center">
          <GalxeIcon className="bg-[white]" />
        </div>
      ),
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.GalaxeQuest,
          title: 'Participate and complete Analog’s Galxe Campaign',
          description:
            "After completing tasks on Galxe, be sure to connect your EVM wallet then click 'Claim' on the Galxe platform. Then, connect the same EVM wallet you used on Galxe to redeem your points here.",
          primaryPoints: '11 ATP',
          claimButton: true,
          maxTaskPoints: 11,
          ...(evmWalletAddress
            ? {
                additionalDescription: (
                  <GalaxeQuestAdditionalDesc
                    questType={GalxeQuestType.GalaxeQuest}
                    accessToken={accessToken}
                    evmWalletAddress={evmWalletAddress}
                    trackEvent={trackEvent}
                  />
                ),
                taskButton: (
                  <QuestTaskButton
                    handleClick={() =>
                      window.open('https://app.galxe.com/quest/analog/GCv1ztzSdd', '_blank')
                    }
                  >
                    Get Started
                  </QuestTaskButton>
                ),
              }
            : {
                taskButton: (
                  <QuestTaskButton
                    handleClick={() => {
                      open();
                      trackEvent('quest_clicks', {
                        communityQuest: 'EVM Wallet Connect From Galxy Quest',
                      });
                    }}
                  >
                    Connect Wallet
                  </QuestTaskButton>
                ),
              }),
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.GalaxeXDysonFinance,
          title: ' Analog x Dyson Finance: Unlock the Future of Finance',
          description:
            "Join this exciting partnership campaign between Analog and Dyson Finance! By completing tasks, you can earn valuable ATP on Analog's testnet platform. Complete the Dyson Surge S2 task to earn even more ATP and the chance to qualify for $DYSN airdrops!",
          primaryPoints: '20 ATP',
          claimButton: true,
          maxTaskPoints: 20,
          ...(evmWalletAddress
            ? {
                additionalDescription: (
                  <GalaxeQuestAdditionalDesc
                    questType={GalxeQuestType.GalaxeXDysonFinance}
                    accessToken={accessToken}
                    evmWalletAddress={evmWalletAddress}
                    trackEvent={trackEvent}
                  />
                ),
                taskButton: (
                  <QuestTaskButton
                    handleClick={() =>
                      window.open('https://app.galxe.com/quest/analog/GCYKotVr6z', '_blank')
                    }
                  >
                    Get Started
                  </QuestTaskButton>
                ),
              }
            : {
                taskButton: (
                  <QuestTaskButton
                    handleClick={() => {
                      open();
                      trackEvent('quest_clicks', {
                        communityQuest: 'EVM Wallet Connect From Galxy Quest',
                      });
                    }}
                  >
                    Connect Wallet
                  </QuestTaskButton>
                ),
              }),
        },
      ],
    },
    {
      heading: 'Dmail',
      widePoints: true,
      headingIcon: <DmailIcon />,
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.DMail,
          title: 'Sign up with Dmail to claim your cross-chain decentralized email address',
          description: 'Earn points both on Dmail and Analog!',
          primaryPoints: '10 ATP',
          secondaryPoints: '80 DP',
          secondaryPointsLabel: 'Dmail Rewards',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open(
                  `${
                    isProduction ? 'https://mail.dmail.ai' : 'https://testmailhu9fg9h.dmail.ai'
                  }/login?analogusercode=${account?.referralCode}`,
                  '_blank'
                );
                trackEvent('quest_clicks', {
                  communityQuest: 'Dmail Quest Claim',
                });
              }}
            >
              Get Started
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.SentEmailOnDmail,
          title: 'Send an email on Dmail with the Analog Network',
          description:
            'Sign into Dmail with Analog using a Substrate wallet and send an email by the Analog Network. Please ensure you use the same Substrate wallet connected here.',
          primaryPoints: '10 ATP',
          taskButton: (
            <QuestTaskButton
              handleClick={handleSentEmailOnDmailClaim}
              isLoading={verifyDmailSendEmailLoading}
              buttonTextAfterClick={account?.walletAddress && 'Verify'}
            >
              {account?.walletAddress ? 'Get Started' : 'Connect Wallet'}
            </QuestTaskButton>
          ),
        },
      ],
    },
    {
      heading: 'Talisman',
      headingIcon: <TalismanIcon />,
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.Talisman,
          title: 'Earn extra points with Talisman quests!',
          description:
            'Use your Talisman wallet to complete quests and earn extra points on Talisman’s quest platform.',
          primaryPoints: '70 XP',
          primaryPointsLabel: 'Talisman Rewards',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open('https://quest.talisman.xyz/', '_blank');
                trackEvent('quest_clicks', {
                  communityQuest: 'Talisman Quest Claim',
                });
              }}
            >
              Get Started
            </QuestTaskButton>
          ),
        },
      ],
    },
    {
      heading: 'Quaere',
      headingIcon: <QuaereIcon />,
      widePoints: true,
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.Quaere,
          title: 'Quaere x Analog: Daily Tarot Reading',
          description:
            'Earn points both on Quaere and Analog! Please ensure you use the same email on both platforms.',
          primaryPoints: '10 ATP',
          secondaryPoints: '10 PTS',
          secondaryPointsLabel: 'STAR Points',
          taskButton: (
            <>
              <QuestTaskButton
                handleClick={handleQuaereQuestClaim}
                isLoading={claimQuaereQuestLoading}
                buttonTextAfterClick={'Verify'}
              >
                Get Started
              </QuestTaskButton>
            </>
          ),
        },
      ],
    },
    {
      heading: 'Zealy',
      headingIcon: <ZealyIcon />,
      widePoints: true,
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.Zealy,
          title: 'Grab your Zealy role on Discord',
          description:
            "Visit Zealy to grab your Zealy Master Discord role on the Analog server. You will need to have level 3 or higher on Analog's Zealy to be eligible for the role.",
          primaryPoints: '10 ATP',
          taskButton: (
            <>
              <QuestTaskButton
                handleClick={handleZealyQuestClaim}
                isLoading={claimZealyQuestLoading}
              >
                {account?.connectedDiscordServer ? 'Verify' : 'Connect Discord'}
              </QuestTaskButton>
            </>
          ),
        },
      ],
    },
    {
      heading: 'Watch Game',
      tasks: [
        {
          rewardType: RewardType.MultipleTime,
          title: 'Participate in a Watch Game Voting Session',
          description: 'You get rewarded for participating.',
          primaryPoints: '10 ATP',
          pointsRewarded: userPoints?.UserPoints?.votingSessionParticipated,
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                navigate(pathTo('Game'));
                trackEvent('quest_clicks', {
                  communityQuest: 'Participate in a Watch Game Voting Session Get Started',
                });
              }}
            >
              Get Started
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.MultipleTime,
          title: (
            <div className="flex flex-row gap-1 mb-1 items-center">
              <div>Your vote wins a Voting Session!</div>
              <div
                className="cursor-default text-xs max-h-[21px] min-w-fit flex items-center px-2 py-1 bg-[linear-gradient(140deg,#D274F74D_16.37%,#9A74F74D_50.02%)] rounded-full relative"
                data-tooltip-id="vote-wins-twice-tooltip"
              >
                <Tooltip
                  id="vote-wins-twice-tooltip"
                  content="If you win twice in a row!"
                  classNames="max-w-[80vw] break-words !bg-[#232224] !border-none"
                  classNameArrow="!border-none"
                  rest={{ arrowColor: '#232224' }}
                />
                X2 PTS
              </div>
            </div>
          ),
          description: 'You get rewarded if the View you voted on wins a Voting Session.',
          primaryPoints: '20 ATP',
          pointsRewarded: userPoints?.UserPoints?.winnerAsVoter,
        },
      ],
    },
  ];
};
