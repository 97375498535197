/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { Button } from './Button';
import { Header } from './Header';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSessionCountdown, useWindowSize } from 'hooks';
import {
  AccountMenu,
  BadgeIcon,
  BottomModal,
  ChevronGradient,
  ConnectWallet,
  CopyButton,
  ExclamationMark,
  GameLogo,
  GradientDotsIcon,
  GradientSwitchArrowsIcon,
  LogOutIcon,
  ProfileBGIcon,
  Skeleton,
  Tooltip,
  UserProfileIcon,
} from 'components';
import { ClassNames, Setter } from 'types';
import { Bars3Icon, ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useApi } from 'context';
import {
  classes,
  customToast,
  desktopWallet,
  getSubstrateWalletType,
  pathTo,
  truncate,
} from 'utils';
import axios from 'axios';
import { useEVM } from 'hooks/quests';
import { useLocalStorage } from 'usehooks-ts';

enum OptionType {
  SwitchSubstrate = 'switchSubstrate',
  SwitchEVM = 'switchEVM',
  SignOut = 'signOut',
}

function getLabel(location: string) {
  switch (location) {
    case '/':
      return 'DashBoard';

    case '/leaderboard':
      return 'Leaderboard';

    case '/quests':
      return 'Quests';

    case '/quests/inactive':
      return 'Quests';

    case '/game/active-session':
      return 'Active Voting Session';

    case '/admin':
      return 'Admin DashBoard';

    case '/faq':
      return 'FAQs';

    default:
      return 'Watch Game';
  }
}

function TopBarHeader({ className }: { className?: ClassNames<'header' | 'accessory'> }) {
  const location = useLocation();

  return (
    <Header
      classNames={{
        header: classes('leading-6 md:text-lg text-base', className?.header),
        accessory: className?.accessory,
        base: className?.base,
      }}
    >
      {getLabel(location.pathname)}
    </Header>
  );
}

function ActiveSessionTimer() {
  const { countdown } = useSessionCountdown();

  return (
    <div className="lg:min-w-[170px]">
      {countdown.split(':').map((val, index) => (
        <span className="lg:text-[28px] text-xl text-white" key={index}>
          <span>{val}</span>
          {index !== countdown.split(':').length - 1 && (
            <span className="lg:mx-[3px] md:mx-[1px] mx-[3px]">:</span>
          )}
        </span>
      ))}
    </div>
  );
}

export function TopBar({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: Setter<boolean> }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMobile } = useWindowSize();
  const { account, onDisconnect, accountLoading, trackEvent } = useApi();
  const {
    evmWalletAddress,
    isEvmWalletConnected,
    open,
    evmEvent,
    disconnectEvmWallet,
    evmWalletInfo,
  } = useEVM();
  const [_, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [show, setShow] = useState<OptionType | undefined>();
  const [substrateWalletIcon, setSubstrateWalletIcon] = useState<string | undefined>();
  const [__, SetIsFP] = useLocalStorage('isFP', '');

  useEffect(() => {
    if (evmEvent === 'SELECT_WALLET') {
      disconnectEvmWallet();
    } else if (evmEvent === 'CONNECT_SUCCESS') {
      customToast('EVM account connected successfully');
    }
  }, [evmEvent, disconnectEvmWallet]);

  useLayoutEffect(() => {
    (async () => {
      if (account?.walletAddress) {
        const walletType = await getSubstrateWalletType(account?.walletAddress);
        setSubstrateWalletIcon(desktopWallet.find((wallet) => wallet.value === walletType)?.img);
      }
    })();
  }, [account?.walletAddress]);

  const handleLogOut = useCallback(() => {
    axios
      .get(`${import.meta.env.VITE_BACKEND_ENDPOINT}auth/logout`, { withCredentials: true })
      .then(() => {
        setIsModalOpen(false);
        onDisconnect();
        customToast('You have successfully signed out!');
        SetIsFP('');
        navigate(pathTo('Home'));
      });
  }, [SetIsFP, navigate, onDisconnect]);

  useEffect(() => {
    setShow(undefined);
  }, [isModalOpen]);

  const content = useMemo(() => {
    if (isMobile) {
      return (
        <>
          <div className="flex items-center justify-between w-full md:py-0 py-[13px] md:px-0 px-4 border-solid border-0  border-b border-dark-gray h-full gap-2">
            <Skeleton.Loader className="h-10 w-32">
              <GameLogo className="w-[150px]" />
            </Skeleton.Loader>
            <div className="flex items-center gap-[10px]">
              {account ? (
                <button
                  className="flex-1 p-[6px] flex flex-row gap-[4px] items-center border border-solid border-steel-gray rounded-lg"
                  onClick={() => setIsModalOpen(true)}
                >
                  <div className="p-[3px] rounded-md bg-black-100">
                    <ProfileBGIcon />
                  </div>
                  <div className="text-xs font-medium text-white line-clamp-1 whitespace-pre-line break-all sm:max-w-unset max-w-[10vw]">
                    {account.name}
                  </div>
                  <ChevronGradient className={classes(isModalOpen && 'rotate-180')} />
                </button>
              ) : (
                <Skeleton.Loader className="h-10 w-20">
                  <Link to={pathTo('SignUp')}>
                    <Button variant="primary" className="h-8 w-[70px]">
                      Sign Up
                    </Button>
                  </Link>
                </Skeleton.Loader>
              )}
              <Skeleton.Loader className="h-8 w-10">
                {isOpen ? (
                  <button onClick={() => setIsOpen((prev) => !prev)}>
                    <XMarkIcon className="text-white h-6 w-6" />
                  </button>
                ) : (
                  <button onClick={() => setIsOpen((prev) => !prev)}>
                    <Bars3Icon className="text-white h-6 w-6" />
                  </button>
                )}
              </Skeleton.Loader>
            </div>

            {account && (
              <BottomModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                title="Account"
                classNames={{ childrenContainer: 'flex flex-col gap-4' }}
              >
                <div className=" flex items-center gap-2">
                  <UserProfileIcon className=" h-8 w-8" />
                  <div className="flex justify-between items-center flex-1">
                    <div className="flex flex-col gap-1 text-white">
                      <h4 className="text-sm font-text-bold leading-[14px] break-all line-clamp-1">
                        {account.name}
                      </h4>
                      <div className="text-gray text-sm leading-[18px] break-all line-clamp-1">
                        {account.email}
                      </div>
                    </div>
                  </div>
                  {account && (
                    <button
                      onClick={() => {
                        if (!account?.isVerifiedByPOH) {
                          window.open(
                            'https://www.intract.io/proof-of-humanity?pohReferralCode=ANALOG',
                            '_blank'
                          );
                        }
                      }}
                      data-tooltip-id="poh-tooltip"
                    >
                      <Tooltip
                        id="poh-tooltip"
                        content="Proof of humanity verified!"
                        disabled={!account?.isVerifiedByPOH}
                      />
                      <BadgeIcon isGreen={account?.isVerifiedByPOH} />
                    </button>
                  )}
                </div>
                <div>
                  <div className="text-white text-sm mb-[8px]">Your Wallets</div>
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-col gap-1">
                      <div className="text-[10px] text-gray uppercase">
                        Substrate Wallet Address
                      </div>
                      {!account.walletAddress ? (
                        <div className="flex gap-1 text-gray text-sm">
                          <div className="flex-1 px-[5px] py-[6px] flex flex-row bg-[#000] shadow-primary-shadow items-center border border-solid border-steel-gray rounded-lg">
                            <div className="flex justify-center items-center md:p-1 p-[10px] rounded-md bg-[#ffffff12] md:max-h-8 max-h-8 md:max-w-12 max-w-10 mr-2">
                              <ExclamationCircleIcon stroke="#FC3B46" className="h-5 w-5" />
                            </div>
                            <div className="text-white">No Wallets Connected</div>
                            <Button
                              variant="light"
                              onClick={() => {
                                setSearchParams({ 'connect-wallet': 'true' });
                                setIsModalOpen(false);
                              }}
                              classNames={{ base: 'ms-auto h-[30px] w-20', container: 'text-xs' }}
                            >
                              Connect
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="text-sm bg-[#000] shadow-primary-shadow px-2 py-2 border border-solid h-10 border-[#393939] rounded-lg">
                          <div className="text-white text-sm flex items-center justify-between gap-2 relative">
                            <div className="flex gap-2 items-center">
                              {substrateWalletIcon ? (
                                <img
                                  alt="substrate-wallet-icon"
                                  src={substrateWalletIcon}
                                  className="h-6 w-6 rounded"
                                />
                              ) : (
                                <div
                                  className="relative"
                                  data-tooltip-id="fetch-wallet-icon-failed"
                                >
                                  <ExclamationMark className="h-6 w-6" />
                                  <Tooltip
                                    id="fetch-wallet-icon-failed"
                                    content="Unable to fetch your wallet icon"
                                    classNames="max-w-[80vw] break-words"
                                  />
                                </div>
                              )}
                              <div>{truncate(account.walletAddress, 9)}</div>
                            </div>
                            <div className="flex sm:gap-3 gap-2">
                              <CopyButton
                                id={'substrate-address-copy'}
                                value={account.walletAddress}
                                gradientIcon
                                className="ml-1"
                              />
                              <div
                                className="text-white cursor-pointer"
                                onClick={() => {
                                  if (show === OptionType.SwitchSubstrate) setShow(undefined);
                                  else setShow(OptionType.SwitchSubstrate);
                                }}
                              >
                                <GradientDotsIcon className="h-5 w-5" color="#51526C" />
                              </div>
                            </div>
                            {show === OptionType.SwitchSubstrate && (
                              <Link
                                to={pathTo('SwitchWallet')}
                                onClick={() => setIsModalOpen(false)}
                                className="flex gap-1 absolute bottom-[-45px] -right-[23px] items-center border bg-[#010101] z-10 border-[#141414] px-3 py-2 rounded-lg text-xs cursor-pointer"
                              >
                                <div className="h-4 w-4 bg-black border border-[#2A2B3A] rounded  border-b-0 border-l-0 absolute right-6 -rotate-45 top-[-8px]  flex"></div>
                                <GradientSwitchArrowsIcon className="h-5 w-5" />
                                <span className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer">
                                  Switch wallet
                                </span>
                              </Link>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="text-[10px] text-gray uppercase">EVM Wallet Address</div>
                      {!isEvmWalletConnected || !evmWalletAddress ? (
                        <div className="flex gap-1 text-gray text-sm">
                          <div className="flex-1 px-[5px] py-[6px] flex flex-row items-center bg-[#000] shadow-primary-shadow border border-solid border-steel-gray rounded-lg">
                            <div className="flex justify-center items-center md:p-1 p-[10px] rounded-md bg-[#ffffff12] md:max-h-8 max-h-8 md:max-w-12 max-w-10 mr-2">
                              <ExclamationCircleIcon stroke="#FC3B46" className="h-5 w-5" />
                            </div>
                            <div className="text-white">No Wallets Connected</div>
                            <Button
                              variant="light"
                              onClick={() => {
                                open && open({ view: 'Connect' });
                                setIsModalOpen(false);
                              }}
                              classNames={{ base: 'ms-auto h-[30px] w-20', container: 'text-xs' }}
                            >
                              Connect
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="text-sm bg-[#000] shadow-primary-shadow px-2 py-2 border border-solid h-10 border-[#393939] rounded-lg">
                          <div className="text-white text-sm flex items-center justify-between gap-2 relative">
                            <div className="flex gap-2 items-center">
                              {evmWalletInfo?.icon && (
                                <img
                                  alt="evm-wallet-icon"
                                  src={evmWalletInfo.icon}
                                  className="h-6 w-6 rounded"
                                />
                              )}
                              <div>{truncate(evmWalletAddress, 10)}</div>
                            </div>
                            <div className="flex sm:gap-3 gap-2">
                              <CopyButton
                                id={'ethereum-address-copy'}
                                value={evmWalletAddress}
                                gradientIcon
                                className="ml-1"
                              />
                              <div
                                className="text-white cursor-pointer"
                                onClick={() => {
                                  if (show === OptionType.SwitchEVM) setShow(undefined);
                                  else setShow(OptionType.SwitchEVM);
                                }}
                              >
                                <GradientDotsIcon className="h-5 w-5" color="#51526C" />
                              </div>
                            </div>
                            {show === OptionType.SwitchEVM && (
                              <div
                                onClick={() => {
                                  open && open({ view: 'Connect' });
                                  setIsModalOpen(false);
                                }}
                                className="flex gap-1 absolute bottom-[-45px] -right-[23px] items-center border bg-[#010101] z-10 border-[#141414] px-3 py-2 rounded-lg text-xs cursor-pointer"
                              >
                                <div className="h-4 w-4 bg-black border border-[#2A2B3A] rounded  border-b-0 border-l-0 absolute right-6 -rotate-45 top-[-8px]  flex"></div>
                                <GradientSwitchArrowsIcon className="h-5 w-5" />
                                <span className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer">
                                  Switch wallet
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <button
                  className="flex flex-row text-[#FF3A5D] gap-1 p-3 text-xs items-center bg-light-Black border border-solid border-steel-gray rounded-lg w-full justify-center"
                  onClick={handleLogOut}
                >
                  <LogOutIcon />
                  Sign out
                </button>
              </BottomModal>
            )}
          </div>
          {!isOpen && (
            <Skeleton.Loader className="w-32 h-8">
              <div className="flex justify-between items-center border-solid border-0  border-b border-dark-gray w-full px-4 py-[11px]">
                <TopBarHeader />
                {pathname.includes(pathTo('AllViews')) && (
                  <div className="flex items-center gap-2">
                    <span className="text-gray text-base">Time left</span>
                    <ActiveSessionTimer />
                  </div>
                )}
              </div>
            </Skeleton.Loader>
          )}
        </>
      );
    }

    return (
      <div className="flex justify-between items-center flex-1">
        <Skeleton.Loader className="w-32 h-8">
          <TopBarHeader />
        </Skeleton.Loader>
        <div className="flex flex-row items-center lg:gap-4 gap-3">
          {pathname.includes(pathTo('AllViews')) && (
            <div className="flex items-center lg:gap-[10px] gap-2">
              <span className="text-gray text-base">Time left</span>
              <ActiveSessionTimer />
            </div>
          )}
          {account && (
            <button
              onClick={() => {
                if (!account?.isVerifiedByPOH) {
                  window.open(
                    'https://www.intract.io/proof-of-humanity?pohReferralCode=ANALOG',
                    '_blank'
                  );
                }
              }}
              data-tooltip-id="poh-tooltip"
            >
              <Tooltip
                id="poh-tooltip"
                content="Proof of humanity verified!"
                disabled={!account?.isVerifiedByPOH}
                delay={200}
              />
              <BadgeIcon isGreen={account?.isVerifiedByPOH} />
            </button>
          )}
          {account ? (
            <>
              <Popover className="relative flex min-w-[138px]">
                {({ open, close }) => (
                  <>
                    <Popover.Button className="bg-black flex items-center border border-steel-gray rounded-lg w-full">
                      <div className="flex-1 p-2 flex flex-row gap-[6px] items-center">
                        <Skeleton.Loader className="w-7 h-7">
                          <div className="p-[5px] rounded-[4px] bg-black-100">
                            <ProfileBGIcon />
                          </div>
                        </Skeleton.Loader>
                        <div className="text-xs font-medium text-white">
                          <Skeleton.Loader className="w-10">{account.name}</Skeleton.Loader>
                        </div>
                        <ChevronGradient className={classes(open && 'rotate-180')} />
                      </div>
                    </Popover.Button>
                    <AccountMenu isOpen={open} close={close} handleLogOut={handleLogOut} />
                  </>
                )}
              </Popover>
            </>
          ) : (
            <div className="flex gap-6 items-center">
              <Skeleton.Loader className="h-8 w-20">
                <Link to={pathTo('SignIn')}>
                  <Button
                    variant="plain"
                    className="uppercase"
                    onClick={() => {
                      trackEvent('gtm_login', {
                        action: 'start_sign_in',
                      });
                    }}
                  >
                    Sign In
                  </Button>
                </Link>
              </Skeleton.Loader>
              <Skeleton.Loader className="h-8 w-20">
                <Link to={pathTo('SignUp')}>
                  <Button
                    onClick={() => {
                      trackEvent('gtm_login', {
                        action: 'start_sign_up',
                      });
                    }}
                    variant="primary"
                  >
                    Sign Up
                  </Button>
                </Link>
              </Skeleton.Loader>
            </div>
          )}
        </div>
      </div>
    );
  }, [
    isMobile,
    account,
    isModalOpen,
    evmWalletAddress,
    isEvmWalletConnected,
    isOpen,
    show,
    handleLogOut,
    setIsOpen,
    open,
    trackEvent,
    evmWalletInfo,
    pathname,
  ]);

  useEffect(() => {
    if (isOpen) {
      document.querySelector('body')?.classList.add('stop-scrolling');
    } else {
      document.querySelector('body')?.classList.remove('stop-scrolling');
    }
  }, [isOpen]);

  return (
    <Skeleton.Provider isLoading={accountLoading}>
      <div className="md:h-[72px] w-full md:px-[60px] px-[0] flex md:flex-row flex-col items-center justify-between border-solid border-0 md:border-b border-dark-gray z-[80] bg-black">
        {content}
        <ConnectWallet />
      </div>
    </Skeleton.Provider>
  );
}
