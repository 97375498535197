export function TickWithBox({ isGreen }: { isGreen?: boolean }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
      <rect width="46" height="46" rx="8" fill={isGreen ? '#21B368' : '#DFFE00'} />
      {!isGreen && <rect width="46" height="46" rx="8" fill="url(#paint0_linear_6867_203508)" />}
      <rect x="0.5" y="0.5" width="45" height="45" rx="7.5" stroke="white" strokeOpacity="0.2" />
      {!isGreen && (
        <path
          d="M0 23H46V38C46 42.4183 42.4183 46 38 46H8C3.58172 46 0 42.4183 0 38V23Z"
          fill="url(#paint1_linear_6867_203508)"
        />
      )}
      <path
        d="M19.3799 23.0001L21.7899 25.4201L26.6199 20.5801"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2399 17.2001C15.2399 16.1401 16.1099 15.2701 17.1699 15.2701H18.8999C19.2999 15.2701 19.8599 15.0601 20.1599 14.8101L21.7399 13.4601C22.4399 12.8701 23.5699 12.8701 24.2499 13.4601L25.8299 14.8101C26.1299 15.0601 26.6999 15.2701 27.0999 15.2701H28.7999C29.8599 15.2701 30.7299 16.1401 30.7299 17.2001V18.9001C30.7299 19.3001 30.9399 19.8601 31.1899 20.1601L32.5399 21.7401C33.1299 22.4401 33.1299 23.5701 32.5399 24.2501L31.1899 25.8301C30.9399 26.1301 30.7299 26.6901 30.7299 27.0901V28.7901C30.7299 29.8501 29.8599 30.7201 28.7999 30.7201H27.0999C26.6999 30.7201 26.1399 30.9301 25.8399 31.1801L24.2599 32.5301C23.5599 33.1201 22.4299 33.1201 21.7499 32.5301L20.1699 31.1801C19.8699 30.9301 19.2999 30.7201 18.9099 30.7201H17.1699C16.1099 30.7201 15.2399 29.8501 15.2399 28.7901V27.0801C15.2399 26.6901 15.0399 26.1201 14.7899 25.8301L13.4399 24.2401C12.8599 23.5501 12.8599 22.4301 13.4399 21.7401L13.9999 21.0801"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {!isGreen && (
        <defs>
          <linearGradient
            id="paint0_linear_6867_203508"
            x1="5.35363"
            y1="5.29382"
            x2="21.4054"
            y2="24.3577"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.1249" stopColor="#D274F7" />
            <stop offset="1" stopColor="#9A74F7" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_6867_203508"
            x1="23"
            y1="23"
            x2="23"
            y2="46"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CB00FE" stopOpacity="0" />
            <stop offset="0.51" stopColor="#46014C" stopOpacity="0.51" />
            <stop offset="1" stopColor="#1B001D" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
}
