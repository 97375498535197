import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { ConnectAccount } from 'components';
import { Maybe, Setter, VoidFn } from 'types';
import { pathTo, web3Accounts, web3Enable, web3FromSource } from 'utils';
import { User, UserPointsQuery, useGetUserLazyQuery, useUserPointsLazyQuery } from 'gql';
import { useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module';
import { useAuth } from './AuthContext';
import { useLocation } from 'react-router-dom';
interface ApiState {
  account: Maybe<User>;
  isConnecting: boolean;
  onConnect: (_?: string | null, wallet?: string) => Promise<void>;
  onConfirm: (
    _: InjectedAccountWithMeta,
    __?: ((_: InjectedAccountWithMeta) => Promise<void>) | null
  ) => Promise<void>;
  onDisconnect: VoidFn;
  accountOptions: InjectedAccountWithMeta[] | null;
  walletAccount: string;
  setWalletAccount: Setter<string>;
  trackEvent: (eventName: string, eventData?: object) => void;
  accountLoading?: boolean;
  walletAccountOptions: InjectedAccountWithMeta[] | null;
  fetchUserPoints: VoidFn;
  fetchUser: VoidFn;
  userPoints: UserPointsQuery | undefined;
  isUserPointsLoading: boolean | undefined;
  queryCooldown: boolean;
  setQueryCooldown: Setter<boolean>;
}

export const ApiContext = createContext<ApiState>({
  walletAccount: null,
  isConnecting: false,
} as unknown as ApiState);

export function ApiProvider({ children }: React.PropsWithChildren) {
  const [{ accessToken }] = useCookies(['accessToken']);
  const { isSuccessLogin } = useAuth();
  const { pathname } = useLocation();
  const [walletAccount, setWalletAccount] = useState('');
  const [isConnecting, setIsConnecting] = useState(false);
  const [showRefresh, setShowRefresh] = useState(false);
  const [account, setAccount] = useState<Maybe<User>>();
  const [accountOptions, setAccountOptions] = useState<InjectedAccountWithMeta[] | null>(null);
  const [queryCooldown, setQueryCooldown] = useState(false);

  const [fetchUser, { data: userData, loading: accountLoading }] = useGetUserLazyQuery({
    variables: { accessToken },
    fetchPolicy: 'cache-and-network',
  });
  const [fetchUserPoints, { data: userPoints, loading: isUserPointsLoading }] =
    useUserPointsLazyQuery({
      variables: { accessToken },
      fetchPolicy: 'cache-and-network',
    });

  const walletAccountOptions = useMemo(() => {
    if (walletAccount === 'all') {
      return (
        accountOptions?.filter(
          (account) =>
            account.meta.source !== 'talisman' &&
            account.meta.source !== 'enkrypt' &&
            account.meta.source !== 'subwallet-js' &&
            account.meta.source !== 'polkadot-js' &&
            account.meta.source !== 'fearless-wallet'
        ) || null
      );
    } else {
      return accountOptions?.filter((account) => account.meta.source === walletAccount) || null;
    }
  }, [walletAccount, accountOptions]);

  const trackEvent = useCallback(
    (eventName: string, eventData?: object) => {
      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          user: account,
          user_email: account?.email,
          ...eventData,
        },
      });
    },
    [account]
  );

  const onDisconnect = useCallback(() => {
    setAccount(null);
  }, [setAccount]);

  const onConfirm = useCallback(
    async (
      account: InjectedAccountWithMeta,
      onComplete?: ((_: InjectedAccountWithMeta) => Promise<void>) | null
    ) => {
      setIsConnecting(true);

      const injector = await web3FromSource(account.meta.source);
      const signRaw = injector?.signer?.signRaw;
      if (signRaw) {
        try {
          const { signature } = await signRaw({
            address: account.address,
            data: '',
            type: 'bytes',
          });

          setIsConnecting(false);
          onComplete && (await onComplete(account));
        } catch (e) {
          console.log(e);
          setIsConnecting(false);
        }
      }
    },
    []
  );

  const onConnect = useCallback(
    async (address?: string | null, wallet?: string) => {
      setIsConnecting(true);

      const timeoutId = setTimeout(() => {
        setShowRefresh(true);
      }, 8000);
      await web3Enable('analog-watch');
      clearTimeout(timeoutId);

      const allAccounts = await web3Accounts({
        ...(wallet && { extensions: [wallet] }),
        accountType: ['sr25519'],
      });

      if (address) {
        const isOwned = allAccounts.some((a) => a.address === address);

        if (!isOwned) {
          onDisconnect();
        }
      } else {
        setAccountOptions(
          [...allAccounts].reduce((unique: InjectedAccountWithMeta[], item) => {
            return unique.find((el) => el.address === item.address) ? unique : [...unique, item];
          }, [])
        );
      }
      setIsConnecting(false);
    },
    [onDisconnect]
  );

  useEffect(() => {
    if (queryCooldown) {
      const cooldownTimeout = setTimeout(() => {
        setQueryCooldown(false);
      }, 15000);
      return () => clearTimeout(cooldownTimeout);
    }
  }, [queryCooldown]);

  useEffect(() => {
    if (isSuccessLogin && !account?.id && accessToken) {
      fetchUser({
        variables: {
          accessToken,
        },
      });
    }
  }, [fetchUser, accessToken, account, isSuccessLogin]);

  useEffect(() => {
    setAccount(userData?.getUser as User);
  }, [userData]);

  useEffect(() => {
    if (
      isSuccessLogin &&
      !userPoints?.UserPoints &&
      [
        pathTo('Home'),
        pathTo('Quests'),
        pathTo('Game'),
        pathTo('ValidatorLeaderboard'),
        pathTo('Leaderboard'),
      ].includes(pathname)
    ) {
      fetchUserPoints();
    }
  }, [pathname, isSuccessLogin]);

  const value = useMemo(
    () => ({
      walletAccount,
      isConnecting,
      onConnect,
      onConfirm,
      onDisconnect,
      accountOptions,
      setWalletAccount,
      account,
      accountLoading,
      trackEvent,
      walletAccountOptions,
      fetchUser,
      fetchUserPoints,
      userPoints,
      isUserPointsLoading,
      queryCooldown,
      setQueryCooldown,
    }),
    [
      walletAccount,
      isConnecting,
      onConnect,
      onConfirm,
      onDisconnect,
      accountOptions,
      account,
      accountLoading,
      trackEvent,
      walletAccountOptions,
      fetchUser,
      fetchUserPoints,
      userPoints,
      isUserPointsLoading,
      queryCooldown,
    ]
  );

  return (
    <ApiContext.Provider value={value}>
      {children}
      <ConnectAccount showRefresh={showRefresh} />
    </ApiContext.Provider>
  );
}

export const useApi = () => useContext(ApiContext);
