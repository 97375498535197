import { DeveloperQuestSubType } from 'gql';
import { RewardType } from './useDeveloperQuests';

import { QuestTaskButton } from './useCommunityQuests';

export const useInactiveQuests = () => {
  return [
    {
      heading: 'Community',
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.Intract,
          title: 'Embark on the Analog Incentivized Odyssey!',
          description:
            'Quest is ended! Connect the same EVM wallet you used on Intract to redeem your points here.',
          primaryPoints: '11 ATP',
          secondaryPoints: '130 XP',
          secondaryPointsLabel: 'Intract Points',
          inactive: true,
          taskButton: (
            <QuestTaskButton
              inactive
              handleClick={() => {
                window.open(
                  'https://www.intract.io/quest/6661a09dfcf066cdbc5bec04?utm_source=dashboard',
                  '_blank'
                );
              }}
            >
              Ended
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.IntractXMetaStreet,
          title: 'Analog X Metastreet: The Takeover Saga',
          description:
            'Quest is ended! Connect the same EVM wallet you used on Intract to redeem your points here.',
          primaryPoints: '11 ATP',
          secondaryPoints: '90 XP',
          secondaryPointsLabel: 'Intract Points',
          inactive: true,
          taskButton: (
            <QuestTaskButton
              inactive
              handleClick={() => {
                window.open(
                  'https://www.intract.io/quest/66b3bf5bddcc9d45b304eb39?utm_source=dashboard',
                  '_blank'
                );
              }}
            >
              Ended
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.GalaxeXCryfiQuest,
          title: 'Analog X Cryfi: $CRFY Initiative Program',
          description:
            'The more tasks you complete, the higher your chances of winning $1000 worth of $CRFY tokens and ATP. Be sure to connect the same EVM wallet you used on Galxe to redeem your points here.',
          primaryPoints: '8 ATP',
          claimButton: true,
          inactive: true,
          taskButton: (
            <QuestTaskButton
              inactive
              handleClick={() =>
                window.open('https://app.galxe.com/quest/analog/GCQCKtxAoj', '_blank')
              }
            >
              Ended
            </QuestTaskButton>
          ),
        },
      ],
    },
  ];
};
